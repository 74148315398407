import React from "react";
import MelbourneGovLogo from "@images/city-of-melbourne-logo-colour.png";
import VictoriaGovLogo from "@images/victoria-logo-colour.png"
import CityOfWhittleseaLogo from "@images/city-of-whittlesea-logo.png";

const Logos = () => {
  return (
    <div className="bg-gray-100">
      <div className="max-w-7xl mx-auto py-8 md:py-12 px-4 sm:px-6 lg:px-8">
        <p className="text-center text-md font-semibold text-gray-500 tracking-wide pb-10">
          Some of the clients we have worked with
        </p>
        <div className="grid grid-cols-2 lg:grid-cols-6 place-items-center">
          <div className="col-span-2 pb-5 lg:pb-0">
            <img className="h-24" src={MelbourneGovLogo} alt="City of Melbourne Logo" />
          </div>
          <div className="col-span-2 pb-5 lg:pb-0">
            <img className="h-24" src={VictoriaGovLogo} alt="Victoria State Government Logo" />
          </div>
          <div className="col-span-2">
            <img className="h-24" src={CityOfWhittleseaLogo} alt="City of Whittlesea Logo" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Logos;
